import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import 'react-sweet-progress/lib/style.css'
import 'typeface-open-sans'
import AuthGuard from './AuthGuard'
import AppContainer from './components/AppContainer'
import PlaybookTypes from './pages/PlaybookTypes/PlaybookTypes'
import CreatePlaybookType from './pages/PlaybookTypes/components/CreatePlaybookType'
import Agent from './pages/agents/AgentPage'
import AgentsPage from './pages/agents/AgentsPage'
import OnboardList from './pages/agents/OnboardList'
import ChallengeApplicationCreate from './pages/challengeApplications/ChallengeApplicationCreate'
import ChallengeApplicationPage from './pages/challengeApplications/ChallengeApplicationPage'
import ChallengeApplicationsList from './pages/challengeApplications/ChallengeApplicationsList'
import ChallengeTypeCreate from './pages/challengeTypes/ChallengeTypeCreate'
import ChallengeTypePage from './pages/challengeTypes/ChallengeTypePage'
import ChallengeTypesList from './pages/challengeTypes/ChallengeTypesList'
import Coach from './pages/coaches/CoachPage'
import CoachesPage from './pages/coaches/CoachesPage'
import CompanyCreate from './pages/company/CompanyCreate'
import CompanyList from './pages/company/CompanyList'
import CompanyPage from './pages/company/CompanyPage'
import EmployeeListPage from './pages/employees/EmployeeListPage'
import Employee from './pages/employees/EmployeePage'
import EventCreate from './pages/events/EventCreate'
import EventsPage from './pages/events/Events'
import Login from './pages/login/Login'
import SecuritySettings from './pages/securitySettings/SecuritySettings'
import StripeSettings from './pages/stripeSettings/StripeSettings'
import ZoomSettings from './pages/zoomSettings/ZoomSettings'

function AppRouter() {
  return (
    <Switch>
      <Route path="/login" component={Login} />

      <AuthGuard>
        <AppContainer>
          <Switch>
            <Route path="/companies" component={CompanyList} />
            <Route path="/company/:id" component={CompanyPage} />

            <Route path="/employees" component={EmployeeListPage} />
            <Route path="/employee/:id" component={Employee} />

            <Route path="/coach/:id" component={Coach} />
            <Route path="/add-company" component={CompanyCreate} />
            <Route path="/add-challenge-application" component={ChallengeApplicationCreate} />
            <Route path="/add-challenge-type" component={ChallengeTypeCreate} />
            <Route path="/challenge-type/:id" component={ChallengeTypePage} />
            <Route path="/challenge-application/:id" component={ChallengeApplicationPage} />
            <Route path="/coaches/choose/:groupId" component={(props) => <CoachesPage {...props} isChooseMode />} />
            <Route path="/coaches" component={CoachesPage} />
            <Route path="/playbook-types" component={PlaybookTypes} />
            <Route path="/create-playbook-type" component={CreatePlaybookType} />
            <Route path="/zoom-settings" component={ZoomSettings} />
            <Route path="/stripe-settings" component={StripeSettings} />
            <Route path="/security-settings" component={SecuritySettings} />

            {/*  updated => */}
            {/* <Route path="/playbooks/choose/:instanceId" component={(props) => <Playbooks {...props} isChooseMode />} /> */}
            {/* <Route path="/playbooks" component={Playbooks} /> */}
            {/* <Route path="/playbook/create" component={PlaybookCreate} /> */}
            <Route path="/events" component={EventsPage} />
            <Route path="/event-create" component={EventCreate} />
            {/* <Route */}
            {/*  path={'/playbook/:id'} */}
            {/*  sensitive */}
            {/*  render={(props) => { */}
            {/*    if (props.match.params.id === 'create') return null */}
            {/*    return <PlayBook {...props} /> */}
            {/*  }} */}
            {/* /> */}

            {/* new v5.7+ */}
            <Route path="/agents" exact component={AgentsPage} />
            <Route path="/agents/onboard-list" component={OnboardList} />
            <Route path="/agent/:id" component={Agent} />

            <Redirect to={'/companies'} />
          </Switch>
        </AppContainer>
      </AuthGuard>
    </Switch>
  )
}

export default AppRouter
