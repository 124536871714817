import { AxiosRequestConfig } from 'axios'
import apiClient from '../../lib/apiClientOld'
import challengeApplicationsApi from './challengeApplicationApi'
import challengeTypeApi from './challengeTypeApi'
import coachesApi from './coachesApi'
import companyApi from './companyApi'
import employeeApi from './employeeApi'
import paymentApi from './paymentApi'
import playbookApi from './playbookApi'
import playbookInstanceApi from './playbookInstanceApi'
import programsApi from './programsApi'
import securityApi from './securityApi'
import zoomHostsApi from './zoomHostsApi'

export const prefix_web = '/web/'
export const prefix_assets = '/assets/'

export default {
  apiClient,
  ...employeeApi,
  ...companyApi,
  ...coachesApi,
  ...programsApi,
  ...playbookApi,
  ...playbookInstanceApi,
  ...zoomHostsApi,
  ...paymentApi,
  ...challengeApplicationsApi,
  ...challengeTypeApi,
  ...securityApi,

  /**
   * Delete user employee or coach
   * @param userId - user number id
   * @returns Object - status inside success or filed
   */
  async deleteUser(userId) {
    const result = await apiClient.delete(`${prefix_web}user/${userId}`)
    return result.data
  },
  /**
   * Make logout all user devices
   * @param userId - user number id
   * @returns {Promise<boolean>} - success or false status
   */
  async logoutUserDevices(userId) {
    // const result = apiClient.get('user/'+ userId+ '/devices/logout')
    // return result.data
    console.log(`devices for user ${userId} make logout `)
    return true
  },
  async sendNotificationToUserApplication(userId, message) {
    const result = await apiClient.post(`${prefix_web}user/${userId}/notification`, {
      title: message.theme,
      body: message.text,
    })
    return result.data
  },

  async uploadImg(file: FormData, options?: AxiosRequestConfig): Promise<AssetFull> {
    const result = await apiClient.post(`${prefix_assets}upload`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      ...options,
    })
    return result.data
  },

  async uploadFile(file: File, options?: AxiosRequestConfig): Promise<AssetFull> {
    const formData = new FormData()
    formData.append('file', file)

    const result = await apiClient.post(`${prefix_assets}upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      ...options,
    })
    return result.data
  },
}
