/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader'
import AntSwitch from '../../elements/switch/AntSwitch'
import PageSection from '../../layout/PageSection'
import api from '../../libs/api/apiOld'
import StatusBoolean from '../company/components/StatusBoolean'

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 20,
  },
  label: {
    fontWeight: 'bold',
    marginRight: 5,
  },
})

function SecuritySettings() {
  const [loading, setLoading] = useState(false)
  const [securityConfig, setSecurityConfig] = useState<{ is_captcha_enabled: boolean }>({ is_captcha_enabled: true })
  const classes = useStyles()

  useEffect(() => {
    const fetchSecurityConfig = async () => {
      try {
        setLoading(true)
        const res = await api.getSecurityConfig()
        setSecurityConfig(res)
      } finally {
        setLoading(false)
      }
    }

    fetchSecurityConfig()
  }, [])

  const handleChangeCaptcha = async (_, checked: boolean) => {
    setLoading(true)
    try {
      await api.updateSecurityConfig({
        is_captcha_enabled: checked,
      })
      setLoading(false)
      setSecurityConfig((prev) => ({ ...prev, is_captcha_enabled: checked }))
    } catch (error) {
      console.error('Error updating security config', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      css={css`
        margin-top: 30px;
        padding: 0 20px;
      `}
    >
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Typography variant={'h1'}>Security Settings</Typography>
      </Grid>

      {loading ? (
        <Loader />
      ) : (
        <PageSection title={'Captcha'}>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Captcha enabled</span>
            <StatusBoolean isTrue={securityConfig?.is_captcha_enabled} />
            <AntSwitch checked={securityConfig?.is_captcha_enabled} onChange={handleChangeCaptcha} />
          </div>
        </PageSection>
      )}
    </div>
  )
}

export default SecuritySettings
