import { prefix_web } from './apiOld'

export default {
  async getSecurityConfig() {
    const result = await this.apiClient.get(`${prefix_web}security-config`)
    return result.data
  },
  async updateSecurityConfig(data) {
    const result = await this.apiClient.put(`${prefix_web}security-config`, data)
    return result.data
  },
}
